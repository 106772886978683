// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

const initialState = {
    error: null,
    admins: [],
    documents: [],
};

const slice = createSlice({
    name: 'adminUsers',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        getUserListSuccess(state, action) {
            state.admins = action.payload;
        },
        getUserServiceTokenSuccess(state, action) {
            state.jwt = action.payload;
        },
        getUserDocumentListSuccess(state, action) {
            state.documents = action.payload.files;
        }
    }
});

export default slice.reducer;

export function getUserList(params) {
    return async () => {
        try {
            let url = '/api/users/' + (params.roleFilter ? params.roleFilter : "ALL");
            if(params) {
                const urlParams = new URLSearchParams(Object.entries(params));
                url += `?${urlParams.toString()}`;
            }
            const response = await axios.get(url);
            dispatch(slice.actions.getUserListSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUserServiceToken(id) {
    return async () => {
        try {
            const response = await axios.get(`/api/users/loginas/${id}`);
            if(response.status === 200) {
                // window.location.href = '/';
                if(response.data && response.data.success) {
                    dispatch(slice.actions.getUserServiceTokenSuccess(response.data.serviceToken));   
                } else {
                    dispatch(slice.actions.hasError(response.data.message));
                }
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateAdmin(id, firstname, lastname, email, username, role, iban, bic, title, company_identifier, street, zip_code, city) {
    return async () => {
        try {
            const response = await axios.put(`/api/users/${id}`, {
                firstname,
                lastname,
                email,
                username,
                role,
                iban, 
                bic,
                title,
                company_identifier,
                street,
                zip_code,
                city
            });
            if(response.status === 200) {
                dispatch(getUserList());
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export const createUser = (firstname, lastname, email, username, password, role, iban, bic, title, company_identifier, street, zip_code, city) => async () => {
    try {
        const response = await axios.post('/api/users', {
            firstname,
            lastname,
            email,
            username,
            password,
            role,
            iban,
            bic,
            title,
            company_identifier,
            street,
            zip_code,
            city
        });
        if(response.status === 200) {
            dispatch(getUserList());
        } else {
            dispatch(slice.actions.hasError(response.data.message));
        }
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
};

export function updateAdminPassword(id, password) {
    return async () => {
        try {
            const response = await axios.put(`/api/users/${id}/password`, {
                password
            });
            if(response.status === 200) {
                dispatch(getUserList());
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function userDocumentList(user) {
    return async () => {
        try {
            const response = await axios.get(`/api/documents/${user ? user : ''}`);
            if(response.status === 200) {
                dispatch(slice.actions.getUserDocumentListSuccess(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export const getDocumentType = (document) => {
    const types = {
        invoice: 'Facture',
        contract: 'Contrat',
        other: 'Autre'
    }

    if(types[document.metadata?.metadata?.documentType]) {
        return types[document.metadata?.metadata?.documentType];
    } else {
        switch(document.name.split('/')[3]) {
            case 'invoices':
                return 'Facture';
            case 'statements':
                return 'Relevé';
            case 'notices':
                return 'Avis d\'opération';
            case 'recaps':
                return 'Récapitulatif';
            case 'advances':
                return 'Avances & Avoirs';
            default:
                return 'Autre';
        }
    }
}

export function deleteUserDocument(user, document) {
    return async () => {
        try {
            const response = await axios.delete(`/api/documents/${user}/${document}`);
            if(response.status === 200) {
                dispatch(slice.actions.getUserDocumentListSuccess(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function uploadUserDocument(user, file, documentType, documentRoom) {
    return async () => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('documentType', documentType);
            formData.append('documentRoom', documentRoom);
            const response = await axios.post(`/api/documents/${user}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if(response.status === 200) {
                dispatch(slice.actions.getUserDocumentListSuccess(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}